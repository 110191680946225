import React from "react";
import { Helmet } from "react-helmet";
import Container from "../../components/chat-channel/Container";
import Layout from "../../components/chat-channel/Layout";
import RegisterSection from "../../components/chat-channel/ChannelHeader";
import RightImageWithContentFeature from "../../components/chat-channel/RightImageWithContent";
import LeftImageWithContent from "../../components/chat-channel/LeftImageWithContent";
import ArrowRightICon from "../../components/common/Icons";
import FooterForm from "../../components/common/FooterForm";
import TitleAndMetaTags from "../../components/common/TitleAndHeader";
import { RequestForm } from "../../components/form";
import Cards from "../../components/chat-channel/Cards";

const topImage = require("../../assets/images/homepage/header_img_home.png");

export const icon1 = require("../../assets/images/10-pages/usp_2/usp_2_sec_1_icon.png");
export const icon2 = require("../../assets/images/10-pages/usp_2/usp_2_sec_2_icon.png");
export const icon3 = require("../../assets/images/10-pages/usp_2/usp_2_sec_3_icon.png");
export const icon4 = require("../../assets/images/10-pages/usp_2/usp_2_sec_4_icon.png");

const section_one = require("../../assets/images/10-pages/usp_2/ticket_auto_chat_sec_1.png");
const section_two = require("../../assets/images/10-pages/usp_2/ticket_auto_chat_sec_2.png");
const section_three = require("../../assets/images/10-pages/usp_2/ticket_auto_chat_sec_3.png");
const section_four = require("../../assets/img/chatbot/it_chatbot/it_chatbot_sec_4.png");

const servicenow = require("../../assets/images/icons-web/servicenow.svg");
const freshservice = require("../../assets/images/icons-web/freshservice.svg");
const jira = require("../../assets/images/icons-web/jira.svg");
const solarwinds = require("../../assets/images/icons-web/solarwinds.svg");
const service_desk = require("../../assets/images/icons-web/service_desk.svg");
const jumpcloud = require("../../assets/images/icons-web/jumpcloud.svg");
const ad = require("../../assets/images/icons-web/zure.svg");
const office365 = require("../../assets/images/icons-web/ms_office.svg");
const okta = require("../../assets/images/icons-web/okta.svg");
const onelogin = require("../../assets/images/icons-web/onelogin.svg");
const bamboo = require("../../assets/images/icons-web/bamboo.svg");
const orangehrm = require("../../assets/images/icons-web/orangehrm.svg");
const zoho_people = require("../../assets/images/icons-web/zoho_people_1.svg");
const gusto = require("../../assets/images/icons-web/gusto.svg");
const sap = require("../../assets/images/icons-web/sap_successfactors.svg");
const intune = require("../../assets/images/icons-web/ms_intune.svg");
const mobileiron = require("../../assets/images/icons-web/mobile_iron.svg");
const hexnode = require("../../assets/images/icons-web/hexnode.svg");
const citrix = require("../../assets/images/icons-web/citrix.svg");
const featureContent = [

  {
    header: "Drive efficiency in your IT helpdesk with ticket automation workflows",
    content:
      "Ticket automation can be cumbersome and need developers to build the integrations and workflows. But at workativ, we have done the heavy lifting, so you can just click and download from over 400+ automations from the marketplace.",
    image: section_one,
    alt:"affordable chatbot builder",
    bgColor: "bg_assist_grey",
  },
  {
    header: "Chatbot for smart ticket classification",
    content:
      "With Workativ, your incoming tickets are qualified by the chatbot and gets passed to a live agent only if required. The tickets to be passed to the agent are categorized and assigned with the right information to the right agent.",
    image: section_two,
    alt:"chatbot with conversational ai and workflow automation",
    bgColor: "bg_assist_white",
  },
  {

    header:
      "Automate tickets to resolve, not just to file",
    content:
      " Smart ticket automation is about resolution. And that’s why workativ comes with in-built workflow automation so you can deliver actual support resolution. one such example, Workativ can send you an OTP securely and unlock your account in seconds – end goal: instant resolution.  ",
  
    link: null,
    image: section_three,
    bgColor: "bg_landing_yellow",
   
  },
  {
    header: "Automate tickets from your Slack or Teams, in few clicks",
    content:
      "Slack or Teams based ticketing have shown to really improve response time, MTTR, and drive better agent and employee experience. ",
    content1:"Delight your employees with always-on 24/7, follow-me, auto-resolution self-service, from the comfort of their favorite apps like Slack or Teams.",
      image: section_four,
    alt:"chatbot with conversational ai and workflow automation",
    bgColor: "bg_assist_white",
    toDisplaySlackIcons: true,
  },
];
const SlackAndTeams = () => (
  <div className="chat_channels_trial">
    <div className="chat_channels_trial_btn">
      <img
        src={require("../../assets/images/trial_landing/slack.png")}
        alt="slack"
      />
      Slack
    </div>
    <div className="chat_channels_trial_btn">
      <img
        src={require("../../assets/images/trial_landing/teams.png")}
        alt="teams"
      />
      Teams
    </div>
  </div>
);

export default function FeatureHomePage() {
  return (
    <>
      <TitleAndMetaTags
        title="Ticket Automation Chatbot - Free Trial | Workativ Assistant Chatbot "
        description="Automate repetitive IT tickets and issues via ticket automation chatbot and deflect tickets away from the helpdesk. Improve helpdesk productivity with AI and automation."
        keywords={["IT Helpdesk Chatbot", "HR Chatbot", "Service Desk Chatbot"]}
        ogTitle="Ticket Automation Chatbot - Free Trial | Workativ Assistant Chatbot"
        ogImage={topImage}
        ogDescription="Automate repetitive IT tickets and issues via ticket automation chatbot and deflect tickets away from the helpdesk. Improve helpdesk productivity with AI and automation."
      />
      <Container>
        <Layout backgroundColor={"bg_usp_page_two"} logoFor="ASSISTANT">
          <RegisterSection
            rightImage={topImage}
            backgroundColor={"bg_usp_page_two"}
            altImage={"Affordable Chatbot platform"}
          >
            <RegisterSection.FormWithContent>
              <RegisterSection.MainHeader>
              Intelligent ticket automation chatbot for your IT Support
              </RegisterSection.MainHeader>
              <RegisterSection.SubHeader>
              Workativ helps you automate your incoming support tickets, save costs, and increase agent productivity by a ton.
              </RegisterSection.SubHeader>
            </RegisterSection.FormWithContent>
          </RegisterSection>{" "}
        
          {featureContent.map((feature, index) => {
          if (index % 2 !== 0) {
            return (
              <RightImageWithContentFeature image={feature.image} bgColor={feature.bgColor} altImage={"HR Chatbot"}>
              <RightImageWithContentFeature.Header>
                <h3>{feature.header}</h3>
              </RightImageWithContentFeature.Header>
              <RightImageWithContentFeature.SubHeader>
                <p dangerouslySetInnerHTML={{ __html: feature.content }} />
                {feature.content1 && <p dangerouslySetInnerHTML={{ __html: feature.content1 }} />}
                {feature.content2 && <p dangerouslySetInnerHTML={{ __html: feature.content2 }} />}
              </RightImageWithContentFeature.SubHeader>
              <RightImageWithContentFeature.Link>
                {feature.link && (
                  <div className="card_link_landing">
                    <a href={feature.url} className="url_manipulation">
                      {feature.link}
                      <span className="arrow_svg_link">
                        <ArrowRightICon />
                      </span>
                    </a>
                  </div>
                )}
                {feature.toDisplaySlackIcons != undefined && (
                  <SlackAndTeams />
                )}
              </RightImageWithContentFeature.Link>
            </RightImageWithContentFeature>
            );
          }
          return (
            <LeftImageWithContent image={feature.image} bgColor={feature.bgColor} altImage={"HR Chatbot"}>
                <LeftImageWithContent.MainHeader>
                  {feature.header}
                </LeftImageWithContent.MainHeader>
                <LeftImageWithContent.SubHeader>
                  <p dangerouslySetInnerHTML={{ __html: feature.content }} />
                  {feature.content1 && <p className="second_p" dangerouslySetInnerHTML={{ __html: feature.content1 }} />}
                  {feature.content2 && <p dangerouslySetInnerHTML={{ __html: feature.content2 }} />}
                </LeftImageWithContent.SubHeader>
                <LeftImageWithContent.Link>
                  <div className="card_link_landing">
                    {feature.link && (
                      <a href={feature.link} className="url_manipulation">
                        Learn more{" "}
                        <span className="arrow_svg_link">
                          <ArrowRightICon />
                        </span>
                      </a>
                    )}
                    {feature.toDisplaySlackIcons != undefined && (
                      <SlackAndTeams />
                    )}
                  </div>
                </LeftImageWithContent.Link>
              </LeftImageWithContent>
            

          );
        })}
        <AppsIntegrations />
         <section className="center_image_home cards_landing_page mb-0">
              <div className="container">
                <div className="col-md-12">
                  <div className="row">
                    <div className="main-slider-left">
                      <h3>Your data is yours and you control it</h3>
                      <p>
                      Workativ has implemented robust security processes and controls that are in compliance with industry-leading standards and regulations.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <Cards cards={cardData} />
            </section>
          <RequestForm isFooterForm={true} />
        </Layout>
      </Container>
    </>
  );
}

function AppsIntegrations() {
  return (
    <section className="apps_landing_page apps_agent_page" id={"integrations"}>
      <div className="container">
        <div className="apps_landing_header_wrapper">
          <div className="apps_landing_header">
            <h3>Automate tickets by connecting 50+ apps, 400+ actions, and 20000+ workflow automations instantly. No coding</h3>
          </div>
          <div className="apps_landing_cards_section">

            {
              myObj.map(apps =>
                <div className="apps_landing_cards col-lg-3 col-md-6 col-12">
                  <h5>{apps.header}</h5>
                  <div className="apps_landing_cards_wrapper">
                    <ul>
                      {apps.userApps.map(apps =>
                        <li>
                          <img src={apps.icon} alt={apps.appName} />
                          <span>{apps.appName}</span>
                        </li>
                      )}
                    </ul>

                  </div>
                </div>
              )
            }
          </div>
          <p className="landing_content_p">Also available Document Management, Expense Management, Email Management, SMS Management, Notifications, and Collaboration Apps.</p>
        </div>
      </div>
    </section>
  )
}

const myObj = [
  {
    "header": "19+ ITSM Apps",
    "userApps": [
      {
        "icon": servicenow,
        "appName": "ServiceNow"
      },
      {
        "icon": freshservice,
        "appName": "Freshservice"
      },
      {
        "icon": jira,
        "appName": "Jira Service Desk"
      },
      {
        "icon": solarwinds,
        "appName": "SolarWinds"
      },
      {
        "icon": service_desk,
        "appName": "ServiceDesk Plus"
      }
    ]
  },
  {
    "header": "15+ Access Management Apps",
    "userApps": [
      {
        "icon": okta,
        "appName": "Okta"
      },
      {
        "icon": ad,
        "appName": "Microsoft Azure AD"
      },
      {
        "icon": jumpcloud,
        "appName": "Jumpcloud"
      },
      {
        "icon": office365,
        "appName": "Microsoft Office 365"
      },
      {
        "icon": onelogin,
        "appName": "Onelogin"
      }
    ]
  },
  {
    "header": "13+ HR Apps",
    "userApps": [
      {
        "icon": bamboo,
        "appName": "BambooHR"
      },
      {
        "icon": orangehrm,
        "appName": "OrangeHRM"
      },
      {
        "icon": zoho_people,
        "appName": "Zoho People"
      },
      {
        "icon": gusto,
        "appName": "Gusto"
      },
      {
        "icon": sap,
        "appName": "SAP SuccessFactors"
      }
    ]
  },
  {
    "header": "09+ MDM Apps",
    "userApps": [
      {
        "icon": intune,
        "appName": "Microsoft Intune"
      },
      {
        "icon": mobileiron,
        "appName": "MobileIron"
      },
      {
        "icon": hexnode,
        "appName": "Hexnode"
      },
      {
        "icon": citrix,
        "appName": "Citrix"
      },
      {
        "icon": service_desk,
        "appName": "ManageEngine MDM"
      }
    ]
  }

]




const noCoding = require("../../assets/images/landing_page/bottom_icon_1.png");
const supportAutomation = require("../../assets/images/landing_page/bottom_icon_2.png");
const boostIT = require("../../assets/images/landing_page/bottom_icon_3.png");

const cardData = [
  {
    header: "Encryption",
    content:
      "AES 256 bit encryption with 1,024-bit key-strength for data at Rest and TLS encryption for data in transit.",
    image: noCoding,
  },
  {
    header: "Access Controls",
    content:
      "Workativ implements role-based access through IAM that enforces segregation of duties, two-factor authentication, and end-to-end audit trails, ensuring access is in accordance with security policy.",
    image: supportAutomation,
  },
  {
    header: "Connect On-prem Apps",
    content:
      "  Workativ enables combining your on-prem apps with cloud apps through its  <span><a href='/conversational-ai-platform/on-prem-app-connector'>secure connector</a></span> so you can create digital workflows to automate tickets without a hitch, be it on-prem or cloud.",
    image: boostIT,
  },
];